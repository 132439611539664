@use 'sass:math';

@mixin fontreset() {
  font-family: var(--font-family-primary);
  font-weight: normal;
  font-size: 4vw;
  font-style: normal;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  margin: 0;
}

@mixin fs($size) {
  $vw_fs: math.div($size, 1920) * 100;
  $px_fs: math.div($size, 16);

  font-size: max(1.2em, #{ math.div($size, 12)+'vw'});

  @include media(desktop) {
    font-size: min(max(1.2rem, #{$vw_fs+'vw'}), #{$px_fs+'rem'});
  }

}

/*-------------------------
// Titles
--------------------------- */

%title {
  @include fontreset();
  font-family: var(--font-family-titles);
  font-weight: normal;
  line-height: 1.3;
  margin-top: 0.325em;
  margin-bottom: 0.325em;
}

$titles: ((72), (60), (48), (36), (30), (24));
@mixin title($i) {
  @extend %title;

  $fs: nth($titles, $i);
  @include fs($fs);
}

// generic elements and classnames for direct usage
@for $i from 1 through 6 {
  h#{$i}, .title-h#{$i} {
    @include title($i);
    word-break: break-word;
  }
}