$medias: (
        hover: (hover: hover) and (any-pointer: fine),
        xs: (max-width: 575px),
        phone: (max-width: 767px),
        tablet: (min-width: 768px),
        mobile: (max-width: 1023px),
        desktop: (min-width: 1024px),
        wide: (min-width: 1200px),
        xwide: (min-width: 1600px),
        xxwide: (min-width: 1920px)
) !default;

// Media queries shortener
// eg.: @include media(phone) {...}
@mixin media( $name ) {
  $str: 'only screen';
  $mq: map-get($medias, $name);

  @each $prop, $value in $mq {
    $str: $str + ' and (#{$prop}: #{$value})';
    @debug $str;
  }

  @media #{$str} {
    @content;
  }
}
