%screen-reader-text,
.sr-only {
  position: absolute !important;
  top: auto;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.link {
  color: var(--mainColor);
  /* Don't really need links underlined. */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  opacity: 0.65;
}

.box {
  margin: var(--space-8) auto;
  padding: var(--space-4);
  border: 1px solid var(--mainColor);

  .box-title {
    margin-top: 0;
  }
}

.reports-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reports-list li {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--mainColor);
  padding: var(--space-2) 0;
}

div.section {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

section {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

div.section-margin-6 {
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
}

div.center {
  text-align: center;
}

div.loader.section {
  margin-top: 10%;
}

.right-align {
  text-align: right;
}

.pill0-card {
  border: solid 1px #191919 !important;
  border-radius: 35px;
  margin-left: 10px;
  margin-bottom: 20px;
  padding: 1.5em 1em 1.5em 1em;
  box-shadow: -1px 0px 0px #eee, 0px 1px 0px #eee, -2px 1px 0px #eee, -1px 2px 0px #eee, -3px 2px 0px #eee, -2px 3px 0px #eee, -4px 3px 0px #eee, -3px 4px 0px #eee, -5px 4px 0px #eee, -4px 5px 0px #eee, -6px 5px 0px #eee, -5px 6px 0px #eee, -7px 6px 0px #eee, -6px 7px 0px #eee, -8px 7px 0px #eee, -7px 8px 0px #eee, -9px 8px 0px #eee, -8px 9px 0px #eee;
  box-sizing: border-box;
  background-color: var(--default-background-color);
}

.rotate.loader {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}