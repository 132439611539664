
html,
body {
  padding: 0;
  margin: 0;
  background-color: var(--default-background-color);
}

html,
body {
  font-family: var(--font-family-primary, sans-serif);
  font-weight: normal;
  line-height: 1.5;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: var(--mainColor-alpha);
  color: var(--textColor);
}

/* Magically responsive typography */
html {
  // This made the font very large when the screen width was a little above 1024px.
  // font-size: 4vw;

  @include media(desktop) {
    font-size: 1rem;
  }
}

hr {
  margin: 2rem auto;
  background: transparent; border: 0;
  border-top: 1px dotted var(--mainColor);
}


h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-titles, sans-serif);
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

* { box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto;
}

main {
  font-size: 1rem;
}

p { margin: 0 0 1em; }