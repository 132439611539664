.showData {
  margin: var(--space-8) auto;
  padding: var(--space-4);
  border: 1px solid var(--mainColor);
}
.showData::-webkit-details-marker {
  display: none;
}
.showData summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}
.showData summary > * {
  margin: 0;
}
.showData summary:after {
  content: "+";
  border-radius: 50%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 2rem;
  line-height: 0.875;
  width: 1em;
  height: 1em;
  background-color: var(--mainColor);
  color: white;
}
.showData[open] summary:after {
  content: "-";
  line-height: 0.96;
}

[open] .showDataContent {
  animation: fade-slide 1s ease-in-out both;
}

.dataSample {
  display: block;
  background-color: #363e48;
  color: var(--mainColor);
  padding: var(--space-4);
  margin: 1em 0;
  overflow: hidden;
  overflow-x: auto;
  max-width: 100%;
}

.sideEffectSection {
  margin-bottom: 2rem;
}

.btn_submit {
  background-color: var(--mainColor);
  color: white;
  margin: var(--space-2) auto;
}

.btn_submit:hover {
  background-color: var(--mainColor-hover);
  text-decoration: none;
}

@keyframes fade-slide {
  0% {
    max-height: 0;
    opacity: 0;
  }
  99% {
    max-height: 100vh;
    opacity: 1;
  }
  100% {
    max-height: none;
    opacity: 1;
  }
}

