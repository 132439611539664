.navbar {
    display: flex;
    align-items: center;
    background-color: var(--default-background-color);
    min-height: 100px;
}

.logo {
    margin-top: var(--space-1);
    margin-right: var(--space-3);
}
.logo img { max-width: 60px; }

.btn_language {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-left: var(--space-2);
    margin-right: var(--space-2);
    min-width: auto;
    white-space: nowrap;
    color: var(--mainColor);
    text-transform: uppercase;
    font-weight: bold;
}

.btn_menu {
    margin-left: var(--space-2);
    margin-right: var(--space-2);
    min-width: auto;
    white-space: nowrap;
    color: var(--textColor);
    text-transform: none;
}

.btn_donate {
    background-color: white;
    color: var(--mainColor);
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    /* Move it up a little bit. */
    margin-bottom: 0.5rem;
}

.ctn_account {
    color: var(--textColor);
    position: relative;
    margin-left: var(--space-2);
    margin-right: var(--space-2);
}

.accountLink {
    margin-right: var(--space-2);
}

.btn_account {
    cursor: pointer;
    font-size: 1.7em;
}

/* Mobile menu */
.btn_toggle {
    margin-right: +0.25em;
}

.drawer {
    width: auto;
    max-width: 75%;
    padding-right: 5%
}

.drawerHeader {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
    min-height: 56px;
}