.searchInput {
    border-radius: 35px;
}

.cardContainer {
    margin-top: var(--space-4);
}

.card .cardTitle {
    margin: 0.25em 0;
}