
/* Each form step (1/3) */
.step {
    text-align: center;
    color: #666;
    margin: var(--space-4) 0;
}

.formStep {
    border: 2px solid var(--mainColor);
    padding: var(--space-8);
    margin-bottom: var(--space-8);
}

.formStep .formTitle {
    margin-top: 0;
}

/* Generic form elements */
.formControl {
    display: block;
    margin: var(--space-4) 0 var(--space-8);
}

.fieldNotice {
    font-size: 0.875em;
    color: #888;
}

.textarea {
    width: 80%;
}

.label {
    margin-bottom: var(--space-1);
    display: block;
}

.btn_submit {
    background-color: var(--mainColor);
    color: white;
    margin: var(--space-2) auto;
}
.btn_submit:hover {
    background-color: var(--mainColor-hover);
    text-decoration: none;
}

/* Show-hide texts */
.infoBox{
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--mainColor-alpha);
    overflow: hidden;
    transition: max-height, opacity, padding;
    transition-duration: 0.2s;
}

.infoBox,
.infoBox[aria-hidden='true'] {
    opacity: 0;
    max-height: 0;
    padding: 0 1em;
    margin: 0;
}

.infoBox[aria-hidden='false'] {
    opacity: 1;
    max-height: 100vh;
    padding: 1em;
    margin: var(--space-4) 0;
}


@keyframes fade-slide {
    0% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 100vh;
        opacity: 1;
    }
}

/* Medical history radio groups */
.multipleQuestions {
    border-bottom: 1px dotted var(--mainColor);
    padding-bottom: 1em; margin-bottom: 1em;
}

.question {
    display: grid;
    grid-template-columns: 1fr 20%;
    align-items: baseline;
    padding: 0; margin: 0;
}

.optionLabel {
    display: inline-flex;
    align-items: center;
    margin-right: 1em;
}

/* Birth Control repeatable section */
.repeatableQuestion {
    margin-bottom: var(--space-8);
}
