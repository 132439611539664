:root {
  --mainColor: #d96d9a;
  --mainColor-hover: #f66696;
  --mainColor-alpha: rgba(244, 143, 177, 0.2);
  --default-background-color: #fffcfa;
  --textColor: #363E48;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --font-family-primary: "Inter", "Arial";
  --font-family-titles: "Inter", "Arial", sans-serif;
}

/*-------------------------
// Titles
--------------------------- */
h6, .title-h6, h5, .title-h5, h4, .title-h4, h3, .title-h3, h2, .title-h2, h1, .title-h1 {
  font-family: var(--font-family-primary);
  font-weight: normal;
  font-size: 4vw;
  font-style: normal;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  margin: 0;
  font-family: var(--font-family-titles);
  font-weight: normal;
  line-height: 1.3;
  margin-top: 0.325em;
  margin-bottom: 0.325em;
}

h1, .title-h1 {
  font-size: max(1.2em, 6vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h1, .title-h1 {
    font-size: min(max(1.2rem, 3.75vw), 4.5rem);
  }
}

h2, .title-h2 {
  font-size: max(1.2em, 5vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h2, .title-h2 {
    font-size: min(max(1.2rem, 3.125vw), 3.75rem);
  }
}

h3, .title-h3 {
  font-size: max(1.2em, 4vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h3, .title-h3 {
    font-size: min(max(1.2rem, 2.5vw), 3rem);
  }
}

h4, .title-h4 {
  font-size: max(1.2em, 3vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h4, .title-h4 {
    font-size: min(max(1.2rem, 1.875vw), 2.25rem);
  }
}

h5, .title-h5 {
  font-size: max(1.2em, 2.5vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h5, .title-h5 {
    font-size: min(max(1.2rem, 1.5625vw), 1.875rem);
  }
}

h6, .title-h6 {
  font-size: max(1.2em, 2vw);
  word-break: break-word;
}
@media only screen and (min-width: 1024px) {
  h6, .title-h6 {
    font-size: min(max(1.2rem, 1.25vw), 1.5rem);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: var(--default-background-color);
}

html,
body {
  font-family: var(--font-family-primary, sans-serif);
  font-weight: normal;
  line-height: 1.5;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: var(--mainColor-alpha);
  color: var(--textColor);
}

/* Magically responsive typography */
@media only screen and (min-width: 1024px) {
  html {
    font-size: 1rem;
  }
}

hr {
  margin: 2rem auto;
  background: transparent;
  border: 0;
  border-top: 1px dotted var(--mainColor);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-titles, sans-serif);
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  font-size: 1rem;
}

p {
  margin: 0 0 1em;
}

.sr-only {
  position: absolute !important;
  top: auto;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.link {
  color: var(--mainColor);
  /* Don't really need links underlined. */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  opacity: 0.65;
}

.box {
  margin: var(--space-8) auto;
  padding: var(--space-4);
  border: 1px solid var(--mainColor);
}
.box .box-title {
  margin-top: 0;
}

.reports-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reports-list li {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--mainColor);
  padding: var(--space-2) 0;
}

div.section {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

section {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

div.section-margin-6 {
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
}

div.center {
  text-align: center;
}

div.loader.section {
  margin-top: 10%;
}

.right-align {
  text-align: right;
}

.pill0-card {
  border: solid 1px #191919 !important;
  border-radius: 35px;
  margin-left: 10px;
  margin-bottom: 20px;
  padding: 1.5em 1em 1.5em 1em;
  box-shadow: -1px 0px 0px #eee, 0px 1px 0px #eee, -2px 1px 0px #eee, -1px 2px 0px #eee, -3px 2px 0px #eee, -2px 3px 0px #eee, -4px 3px 0px #eee, -3px 4px 0px #eee, -5px 4px 0px #eee, -4px 5px 0px #eee, -6px 5px 0px #eee, -5px 6px 0px #eee, -7px 6px 0px #eee, -6px 7px 0px #eee, -8px 7px 0px #eee, -7px 8px 0px #eee, -9px 8px 0px #eee, -8px 9px 0px #eee;
  box-sizing: border-box;
  background-color: var(--default-background-color);
}

.rotate.loader {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

