:root {
  --mainColor: #d96d9a;
  --mainColor-hover: #f66696;
  --mainColor-alpha: rgba(244, 143, 177, 0.2);
  --default-background-color: #fffcfa;
  --textColor: #363E48;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --font-family-primary: "Inter", "Arial";
  --font-family-titles: "Inter", "Arial", sans-serif;
}